 * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

body {
    background: linear-gradient(90deg, #0700b8 0%, #00ff88 100%);
}

.Todo-app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 550px;
    min-height: 500px;
    background: #0c0303;
    text-align: center;
    margin: 50px auto;
    border-radius: 15px;
    padding-bottom: 32px;
}

h1 {
    margin: 32px 0;
    color: #fff;
    font-size: 24px;
}

.complete {
    text-decoration: line-through;
    opacity: 0.4;
}

.todo-form {
    margin-bottom: 32px;
}

.todo-input {
    padding: 14px 32px 14px 16px;
    border-radius: 4px 0 0 4px;
    border: 2px solid #5d0cff;
    outline: none;
    width: 320px;
    background: transparent;
    color: #fff;
}

.todo-input::placeholder {
    color: #e2e2e2;
}

.todo-button {
    padding: 16px;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    outline: none;
    background: linear-gradient(
        90deg,
        rgba(93, 12, 255, 1) 0%,
        rgb(7, 228, 209) 100%
    );
    color: #fff;
    text-transform: capitalize;
}

.todo-input.edit {
    border: 2px solid #149fff;
}

.todo-button.edit {
    background: linear-gradient(
        90deg,
        rgba(20, 159, 255, 1) 0%
        rgba(17, 122, 255, 1) 100%
    );
    padding: 16px 22px;
}

.todo-container {
    display: flex;
    flex-direction: row;
    position: relative;
}

.todo-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px auto;
    color: #fff;
    background: linear-gradient(
    90deg,
    rgb(17, 48, 9) 0%,
    rgb(66, 252, 5) 100%
    );
    padding: 16px;
    border-radius: 5px;
    width: 90%;
}

.todo-row:nth-child(4n + 1) {
    background: linear-gradient(
        90deg,
        rgb(50, 6, 244) 0%,
        rgb(0, 250, 237) 100%
    );
}

.todo-row:nth-child(4n + 2){
    background: linear-gradient(
        90deg,
        rgb(16, 14, 16) 0%,
        rgb(17, 0, 250) 100%
    );
}

.todo-row:nth-child(4n + 3) {
    background: linear-gradient(
        90deg,
        rgba(20, 159, 255, 1) 0%,
        rgba(17, 122, 255, 1) 100%
    );
}

.icons {
    display: flex;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
}

.delete-icon {
    margin-right: 5px;
    color: #fff;
}

.edit-icon{
    color: #fff;
}



